import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Recent Projects`}</h2>
    <ProjectCard title="Agent One" link="https://agnt.one" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
 Agent One is our smart customer service platform that helps businesses provide great support to their customers. It’s easy to use, with helpful features like AI chatbots, live chat, automated tasks, and connections to the tools businesses already use.
    </ProjectCard>
    <ProjectCard title="WellHealth" link="https://wellhealth.studio" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  We spearheaded the revamp of WellHealth's corporate site, a provider of comprehensive
  COVID-19 care. The company delivers easy, scalable solutions backed by robust technology
  to meet testing and treatment needs. They created unique response protocols in response
  to the COVID-19 pandemic, delivering over 1 million tests across multiple states and
  three countries.
    </ProjectCard>
    <ProjectCard title="Classrm" link="https://classrm.app/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Classrm is a classroom management platform dedicated to aiding teachers, parents, and
  administrators in tiny schools. With its roots in addressing the pain points of a
  family-run small dance school, Classrm allows users to manage student information,
  attendance, grading, and communication in a unified platform.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      