/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Grid, Box, Image } from '@theme-ui/components';
import Link from 'gatsby-link';
import Divider from '@lekoarts/gatsby-theme-cara/src/elements/divider';
import Inner from '@lekoarts/gatsby-theme-cara/src/elements/inner';
import Content from '@lekoarts/gatsby-theme-cara/src/elements/content';
import SVG from '@lekoarts/gatsby-theme-cara/src/components/svg';
import { UpDown, UpDownWide } from '@lekoarts/gatsby-theme-cara/src/styles/animations';
import ServicesMDX from '../sections/services.mdx';

const Services = ({ offset }: { offset: number }) => (
  <div>
    <Divider
      bg="linear-gradient(to right, #0B132B 0%, #3A506B 100%)"
      sx={{ clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)` }}
      speed={-0.2}
      offset={1.1}
      factor={2}
    />

    <Content speed={0.4} offset={offset}>
      <Inner sx={{ marginTop: ['4em', 0, 0] }}>
        <ServicesMDX />
        <Grid
          gap={[1, 3, 6]}
          columns={[1, null, null, null, 3]}
          sx={{ marginTop: '5rem' }}
        >
          <Box sx={{ margin: '0 auto', paddingTop: '15px' }}>
            <img
              className="Landing-service-image gradient-1"
              height="300px"
              width="auto"
              alt="man buying from a stall"
              src="./icon-ecommerce.png"
            />
            <h3
              sx={{
                fontSize: '32px',
                textAlign: 'center',
                textDecoration: 'none',
                color: 'white',
              }}
            >
              Ecommerce Builds
            </h3>
          </Box>
          <Box sx={{ margin: '0 auto', paddingTop: '15px' }}>
            <img
              className="Landing-service-image gradient-2"
              height="300px"
              width="auto"
              alt="ice cream with an S on it"
              src="./icon-saas.png"
            />
            <h3
              sx={{
                fontSize: '32px',
                textAlign: 'center',
                paddingTop: '17px',
                textDecoration: 'none',
                color: 'white',
              }}
            >
              SaaS Solutions
            </h3>
          </Box>
          <Box sx={{ margin: '30px auto 0' }}>
            <img
              className="Landing-service-image gradient-3"
              height="280px"
              width="auto"
              alt="smartphone"
              src="./icon-phone.png"
            />
            <h3
              sx={{
                fontSize: '32px',
                textAlign: 'center',
                textDecoration: 'none',
                color: 'white',
                marginTop: '3rem',
              }}
            >
              Mobile Apps
            </h3>
          </Box>
        </Grid>
      </Inner>
    </Content>
    <Divider speed={0.1} offset={offset} factor={2}>
      <UpDown>
        <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
        <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
        <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%" />
        <SVG
          icon="circle"
          hiddenMobile
          width={24}
          color="icon_brightest"
          left="17%"
          top="60%"
        />
      </UpDown>
      <UpDownWide>
        <SVG
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_green"
          left="20%"
          top="90%"
        />
        <SVG
          icon="triangle"
          width={12}
          stroke
          color="icon_brightest"
          left="90%"
          top="30%"
        />
        <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
        <SVG
          icon="triangle"
          hiddenMobile
          width={16}
          stroke
          color="icon_teal"
          left="18%"
          top="75%"
        />
        <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
        <SVG
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_green"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <SVG
        icon="circle"
        hiddenMobile
        width={6}
        color="icon_brightest"
        left="4%"
        top="20%"
      />
      <SVG icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
      <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
      <SVG icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
      <SVG icon="hexa" width={16} stroke color="icon_red" left="75%" top="30%" />
      <SVG icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="70%" />
    </Divider>
  </div>
);

export default Services;
