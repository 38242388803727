/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Divider from '@lekoarts/gatsby-theme-cara/src/elements/divider';
import Inner from '@lekoarts/gatsby-theme-cara/src/elements/inner';
import Content from '@lekoarts/gatsby-theme-cara/src/elements/content';
import SVG from '@lekoarts/gatsby-theme-cara/src/components/svg';
import { UpDown, UpDownWide } from '@lekoarts/gatsby-theme-cara/src/styles/animations';
import Intro from '../sections/intro.mdx';
import BookCall from './book-call';

const Hero = ({ offset }: { offset: number }) => {
  return (
    <div>
      <Divider speed={0.2} offset={offset}>
        <UpDown>
          <SVG icon="cone" hiddenMobile width={48} color="#6FFFE9" left="10%" top="20%" />
          <SVG icon="cone" width={24} color="#6FFFE9" left="60%" top="70%" />
          <SVG icon="box" width={6} color="icon_darker" left="60%" top="15%" />
        </UpDown>
        <UpDownWide>
          <SVG
            icon="arrowUp"
            hiddenMobile
            width={16}
            color="icon_blue"
            left="80%"
            top="10%"
          />
          <SVG
            icon="spiral"
            width={12}
            stroke
            color="icon_brightest"
            left="90%"
            top="50%"
          />
          <SVG icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
          <SVG
            icon="triangle"
            width={16}
            stroke
            color="icon_darkest"
            left="30%"
            top="65%"
          />
          <SVG icon="spiral" width={24} stroke color="icon_pink" left="28%" top="15%" />

          <SVG icon="cone" width={6} color="#6FFFE9" left="75%" top="10%" />
          <SVG
            icon="upDown"
            hiddenMobile
            width={8}
            color="icon_darkest"
            left="45%"
            top="10%"
          />
        </UpDownWide>
        <SVG
          icon="circle"
          hiddenMobile
          width={24}
          color="icon_darker"
          left="5%"
          top="70%"
        />
        <SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
        <SVG icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
        <SVG icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
        <SVG
          icon="upDown"
          hiddenMobile
          width={24}
          color="icon_darker"
          left="40%"
          top="80%"
        />
        <SVG icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
        <SVG icon="circle" width={64} color="icon_green" left="95%" top="5%" />
        <SVG
          icon="spiral"
          hiddenMobile
          stroke
          width={64}
          color="icon_purple"
          left="5%"
          top="90%"
        />
        <SVG icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
        <SVG icon="cone" width={12} color="#6FFFE9" left="40%" top="30%" />
        <SVG icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
        <SVG icon="spiral" width={8} stroke color="icon_darker" left="80%" top="70%" />
      </Divider>
      <Content speed={0.4} offset={offset}>
        <Inner>
          <Intro />
          <div className="Hero__cta">
            <BookCall />
            {/*<Link to="/pricing">*/}
            {/*  <Fab*/}
            {/*    variant="extended"*/}
            {/*    color="primary"*/}
            {/*    className="Footer-call-fab pricing-cta"*/}
            {/*  >*/}
            {/*    See Our Pricing*/}
            {/*  </Fab>*/}
            {/*</Link>*/}
          </div>
        </Inner>
      </Content>
    </div>
  );
};

export default Hero;
