import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-spring/renderprops-addons.cjs';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import Hero from '../components/hero';
import Services from '../components/services';
import Projects from '../components/projects';
import Contact from '@lekoarts/gatsby-theme-cara/src/components/contact';
import MenuBar from '../components/menu-bar';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';

const Cara = () => {
  const [heightAdjust, setHeightAdjust] = useState(0);
  useEffect(() => {
    let adjustment = window.innerHeight < 897 ? 0.1 : 0;
    window.innerHeight < 600 ? (adjustment += 0.3) : adjustment;
    window.innerWidth < 414 ? (adjustment += 0.2) : adjustment;
    setHeightAdjust(adjustment);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Swirl Creative - Software Agency" />
      <Layout>
        <Parallax pages={5.2 + heightAdjust}>
          <MenuBar />
          <Hero offset={0} />
          <Services offset={1.3} />
          <Projects offset={2.3} />
          <Contact offset={4} />
        </Parallax>
        <form name="contact" data-netlify="true" data-netlify-honeypot="bot-field" hidden>
          <input type="text" name="company" />
          <input type="text" name="firstName" />
          <input type="text" name="lastName" />
          <input type="email" name="email" />
          <input type="phone" name="phone" />
          <textarea name="message" />
          <input type="checkbox" name="solicit" />
        </form>
      </Layout>
    </ThemeProvider>
  );
};

export default Cara;
