/** @jsx jsx */
import { jsx } from 'theme-ui';
import Divider from '@lekoarts/gatsby-theme-cara/src/elements/divider';
import Inner from '@lekoarts/gatsby-theme-cara/src/elements/inner';
import Content from '@lekoarts/gatsby-theme-cara/src/elements/content';
import SVG from '@lekoarts/gatsby-theme-cara/src/components/svg';
import { UpDown, UpDownWide } from '@lekoarts/gatsby-theme-cara/src/styles/animations';
import ProjectsMDX from '../sections/projects.mdx';

const Projects = ({ offset }: { offset: number }) => (
  <div>
    <Divider
      bg="divider"
      clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
      speed={0.2}
      offset={offset}
    />
    <Divider speed={0.1} offset={offset}>
      <UpDown>
        <SVG icon="box" hiddenMobile width={6} color="icon_blue" left="50%" top="75%" />
        <SVG
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="70%"
          top="20%"
        />
        <SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
        <SVG icon="cone" hiddenMobile width={24} color="#6FFFE9" left="80%" top="80%" />
      </UpDown>
      <UpDownWide>
        <SVG
          icon="spiral"
          stroke
          hiddenMobile
          width={48}
          color="icon_pink"
          left="5%"
          top="80%"
        />
        <SVG
          icon="spiral"
          width={12}
          stroke
          color="icon_brightest"
          left="95%"
          top="50%"
        />
        <SVG
          icon="circle"
          hiddenMobile
          width={6}
          color="icon_brightest"
          left="85%"
          top="15%"
        />
        <SVG
          icon="spiral"
          stroke
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <SVG
        icon="circle"
        hiddenMobile
        width={6}
        color="icon_brightest"
        left="4%"
        top="20%"
      />
      <SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
      <SVG icon="spiral" stroke width={6} color="icon_orange" left="10%" top="10%" />
      <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
      <SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
    </Divider>
    <Content speed={0.4} offset={offset + 0.2} factor={2}>
      <Inner>
        <div
          sx={{
            display: `grid`,
            a: { color: `white !important` },
            gridGap: [4, 4, 4, 5],
            gridTemplateColumns: [`1fr`, `1fr`, `repeat(2,1fr)`, `repeat(3, 1fr)`],
            h2: { position: `absolute`, bottom: 0, color: `white !important` },
            div: { minWidth: `250px` },
          }}
        >
          <ProjectsMDX />
        </div>
      </Inner>
    </Content>
  </div>
);

export default Projects;
